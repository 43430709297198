import React, { forwardRef } from "react";
import styles from "./styles.module.scss";
import cn from 'classnames';
import { Button } from "react-bootstrap";

const ButtonRedBlack = forwardRef(({ children, ...props }, ref) => {
  return (
    <Button {...props} ref={ref} className={cn(styles.root, props.className)}>
      {children}
    </Button>
  );
});

export default ButtonRedBlack;

import React from "react";

import Base from "../layouts/Base";
import NotFound from "components/Errors/NotFound";
import { NOT_FOUND_URL, DEFAULT_IMG_URL } from "config/constants";
import { responsiveImage } from "../helpers/cdn";
import Head from "../components/Head";

export default function FourOhFour(props) {
  return (
    <Base {...props}>
      <Head>
        <title>{"404 Error -  Real America's Voice News"}</title>
        <meta
          name="description"
          content="Real America's Voice News is your one stop news and entertainment network."
        />
        <meta name="og:title" content={"Real America's Voice News"} />
        <meta property="og:type" content="website" />
        <meta
          name="og:description"
          content="Real America's Voice News is your one stop news and entertainment network."
        />
        <meta property="og:image" content={DEFAULT_IMG_URL} />
        <link
          rel="preload"
          as="image"
          href={NOT_FOUND_URL}
          imagesrcset={`${responsiveImage(
            NOT_FOUND_URL,
            270
          )} 270w, ${responsiveImage(
            NOT_FOUND_URL,
            320
          )} 320w, ${responsiveImage(
            NOT_FOUND_URL,
            530
          )} 530w, ${responsiveImage(
            NOT_FOUND_URL,
            620
          )} 620w, ${responsiveImage(
            NOT_FOUND_URL,
            740
          )} 740w, ${responsiveImage(NOT_FOUND_URL, 779)} 779w`}
          imagesizes="((min-width: 200px) AND (max-width: 991px)) calc(100vw - 54px), 62vw"
        />
        <meta name="robots" content="noindex" />
      </Head>
      <NotFound />
    </Base>
  );
}

export async function getStaticProps() {
  const apiPrivateUrl = process.env.apiPrivateUrl;

  try {
    const apiEndpoints = [
      `${apiPrivateUrl}/menu/sidebar`,
      `${apiPrivateUrl}/menu/footer`,
      `${apiPrivateUrl}/menu/header`,
    ];

    const [sidebarMenu, footerMenu, headerMenu] = await Promise.all(
      apiEndpoints.map((endpoint) =>
        fetch(endpoint)
          .then((response) => response.json())
          .catch((error) => console.error(error))
      )
    );
    return {
      props: {
        sidebarMenu,
        footerMenu,
        headerMenu,
        headerType: "homeHeader",
      },
    };
  } catch (e) {
    console.group("pages/404.jsx - getStaticProps");
    console.log(e);
    console.groupEnd();

    return {
      props: {
        sidebarMenu: [],
        headerType: "default",
      },
    };
  }
}

import React from "react";
import styles from "./styles.module.scss";
import Link from "next/link";
import ButtonRedBlack from "components/common/buttons/ButtonRedBlack";
import { responsiveImage } from "helpers/cdn";
import { NOT_FOUND_URL } from "config/constants";

export default function NotFound() {
  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: "#iframeChat {display: none !important;}",
        }}
      />
      <div className="container pt-5 d-flex flex-column align-items-center">
        <img
          className="img-fluid"
          src={responsiveImage(NOT_FOUND_URL, 800)}
          srcSet={`${responsiveImage(
            NOT_FOUND_URL,
            270
          )} 270w, ${responsiveImage(
            NOT_FOUND_URL,
            320
          )} 320w, ${responsiveImage(
            NOT_FOUND_URL,
            530
          )} 530w, ${responsiveImage(
            NOT_FOUND_URL,
            620
          )} 620w, ${responsiveImage(
            NOT_FOUND_URL,
            740
          )} 740w, ${responsiveImage(NOT_FOUND_URL, 779)} 779w`}
          width="800"
          height="272"
          sizes="((min-width: 200px) AND (max-width: 991px)) calc(100vw - 54px), 62vw"
          alt="Page not found"
        />
        <p className={styles.text}>Ooops... Page Not Found</p>
        <Link href="/">
          <ButtonRedBlack href="/">BACK TO HOMEPAGE</ButtonRedBlack>
        </Link>
      </div>
    </>
  );
}
